<script lang="ts">
  import type { MarkType } from "prosemirror-model";
  import type { EditorState } from "prosemirror-state";
  import type { EditorView } from "prosemirror-view";
  import { toggleMark } from "prosemirror-commands";

  import { commandListener, markIsActive } from "../ps-utils";

  export let view: EditorView<any>;
  export let state: EditorState<any>;
  export let type: MarkType;
  export let faIcon: string;

  $: isActive = markIsActive(state, type);
  $: listener = commandListener(view, toggleMark(type));
</script>

<button type="button" class:active={isActive} on:mousedown={listener}
  ><i class={`fa fa-${faIcon}`} /></button
>
