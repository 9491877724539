<script lang="ts">
  import { onMount, onDestroy, tick } from "svelte";
  import type { EditorView } from "prosemirror-view";
  import { EditorState, TextSelection } from "prosemirror-state";
  import type { Node as ProsemirrorNode } from "prosemirror-model";
  import { toggleMark } from "prosemirror-commands";
  import type { Mark } from "prosemirror-model";

  import type { Command } from "./ps-utils";
  import {
    updateMark,
    removeMark,
    markIsActive,
    commandListener,
    getFirstMarkInSelection,
  } from "./ps-utils";
  import { refreshCoords as _refreshCoords } from "./bubblemenu/coords";
  import SimpleMarkItem from "./bubblemenu/SimpleMarkItem.svelte";

  export let view: EditorView<any>;
  export let state: EditorState<any>;

  // == Posicionamiento ==
  let bubbleEl: HTMLElement = null;
  let left: number = 0;
  let bottom: number = 0;

  function refreshCoords() {
    const coords = _refreshCoords(view, bubbleEl);
    left = coords.left;
    bottom = coords.bottom;
  }

  $: {
    // refrescar cuando cambia state
    state;
    if (bubbleEl) refreshCoords();
  }

  let resizeObserver = null;
  onMount(() => {
    resizeObserver = new ResizeObserver(() => {
      refreshCoords();
    });
    resizeObserver.observe(bubbleEl.parentElement);
  });
  onDestroy(() => {
    resizeObserver.unobserve(bubbleEl.parentElement);
  });
  // == /Posicionamiento ==

  let changingProp:
    | false
    | { type: "link"; url: string }
    | { type: "mark" }
    | { type: "mark-custom"; color: string } = false;

  let linkInputEl: HTMLElement;

  $: {
    if (state.selection.empty) {
      changingProp = false;
    }
    if (changingProp && changingProp.type === "link") {
      tick().then(() => {
        linkInputEl.focus();
      });
    } else {
      view.focus();
    }
  }

  function runCommand(command: Command) {
    command(state, view.dispatch);
  }

  function startEditingLink(event: Event) {
    const { to, from } = state.selection;
    const match = getFirstMarkInSelection(state, view.state.schema.marks.link);

    // si no hay un link en la selección, empezar a editar uno sin ningún enlace
    // TODO: quizás queremos poner algo tipo https://sutty.nl por defecto?
    if (!match) {
      changingProp = { type: "link", url: "" };
      return;
    }

    view.dispatch(
      state.tr.setSelection(
        TextSelection.create(
          state.doc,
          match.position,
          match.position + match.node.nodeSize
        )
      )
    );
    changingProp = { type: "link", url: match.mark.attrs.href };
  }

  function removeLink() {
    changingProp = false;
    runCommand(removeMark(view.state.schema.marks.link));
  }

  function onChangeLink(event: Event) {
    changingProp = false;
    const url = (event.target as HTMLInputElement).value;
    runCommand(updateMark(view.state.schema.marks.link, { href: url }));
  }

  function startMarking() {
    changingProp = { type: "mark" };
  }

  function startMarkingCustom() {
    const match = getFirstMarkInSelection(state, view.state.schema.marks.mark);

    if (!match) {
      changingProp = { type: "mark-custom", color: "#f206f9" };
      return;
    }
    changingProp = { type: "mark-custom", color: match.mark.attrs.color };
  }

  function setMark(color: string | null) {
    if (color) {
      runCommand(updateMark(view.state.schema.marks.mark, { color }));
    } else {
      runCommand(removeMark(view.state.schema.marks.mark));
    }
  }

  const colors = [
    { name: "Rojo", color: "#ff7a7a" },
    { name: "Naranja", color: "#ffa500" },
    { name: "Amarillo", color: "#ffff00" },
    { name: "Verde", color: "#a9ffa9" },
    { name: "Azul", color: "#afafff" },
    { name: "Fucsia", color: "#ff00ff" },
  ];
</script>

<div
  bind:this={bubbleEl}
  class="bubble"
  hidden={state.selection.empty}
  style="left: {left}px; bottom: {bottom}px"
>
  {#if changingProp === false}
    <SimpleMarkItem
      {view}
      {state}
      type={view.state.schema.marks.strong}
      faIcon="bold"
    />
    <SimpleMarkItem
      {view}
      {state}
      type={view.state.schema.marks.em}
      faIcon="italic"
    />
    <SimpleMarkItem
      {view}
      {state}
      type={view.state.schema.marks.underline}
      faIcon="underline"
    />
    <SimpleMarkItem
      {view}
      {state}
      type={view.state.schema.marks.strikethrough}
      faIcon="strikethrough"
    />
    <button
      type="button"
      class:active={markIsActive(state, view.state.schema.marks.mark)}
      on:mousedown|preventDefault={startMarking}
      ><i class="fa fa-tint" /></button
    >
    <button
      type="button"
      class:active={markIsActive(state, view.state.schema.marks.link)}
      on:mousedown|preventDefault={startEditingLink}
      ><i class="fa fa-external-link" /></button
    >
  {:else if changingProp.type === "link"}
    <input
      bind:this={linkInputEl}
      type="text"
      placeholder="https://"
      on:change|preventDefault={onChangeLink}
      value={changingProp.url}
    />
    <button
      type="button"
      title="Borrar enlace"
      on:mousedown|preventDefault={removeLink}><i class="fa fa-close" /></button
    >
  {:else if changingProp.type === "mark"}
    {#each colors as color}
      <button
        type="button"
        class="color-button"
        title={color.name}
        on:mousedown={() => setMark(color.color)}
        ><span
          class="color"
          style={`background-color: ${color.color}`}
        /></button
      >
    {/each}
    <button type="button" title="Elegir color" on:mousedown={startMarkingCustom}
      ><i class="fa fa-plus" /></button
    >
    <button type="button" title="Sin color" on:mousedown={() => setMark(null)}
      ><i class="fa fa-close" /></button
    >
  {:else if changingProp.type === "mark-custom"}
    <div>
      <p>
        Tené en cuenta que usar algunos colores pueden introducir problemas de
        accesibilidad por la falta de contraste.
      </p>
      <input
        name="color"
        type="color"
        on:input|preventDefault={(e) => setMark(e.target.value)}
        value={changingProp.color}
      />
    </div>
  {/if}
</div>
