import { EditorState, Plugin } from "prosemirror-state";
import {
  Decoration,
  DecorationSet,
  WidgetDecorationSpec,
} from "prosemirror-view";
import * as ActiveStorage from "@rails/activestorage";
import { h } from "./utils";

export function uploadFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const upload = new ActiveStorage.DirectUpload(
      file,
      origin + "/rails/active_storage/direct_uploads"
    );

    upload.create((error: any, blob: any) => {
      if (error) {
        reject(error);
      } else {
        const url = `${origin}/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
        resolve(url);
      }
    });
  });
}

export const placeholderPlugin = new Plugin({
  state: {
    init(): DecorationSet {
      return DecorationSet.empty;
    },
    apply(tr, set: DecorationSet) {
      // Adjust decoration positions to changes made by the transaction
      set = set.map(tr.mapping, tr.doc);
      // See if the transaction adds or removes any placeholders
      let action = tr.getMeta(this);
      if (action && action.add) {
        let widgetEl = h("div", { class: "ProseMirror-placeholder" }, [
          "Subiendo archivo...",
        ]);
        let deco = Decoration.widget(action.add.pos, widgetEl, {
          id: action.add.id,
        });
        set = set.add(tr.doc, [deco]);
      } else if (action && action.remove) {
        set = set.remove(
          set.find(undefined, undefined, (spec) => spec.id == action.remove.id)
        );
      }
      return set;
    },
  },
  props: {
    decorations(state) {
      return this.getState(state);
    },
  },
});

export function findPlaceholder(state: EditorState, id: any): number | null {
  const decos: DecorationSet = placeholderPlugin.getState(state);
  const found = decos.find(
    undefined,
    undefined,
    (spec) => spec.id == id
  ) as (Decoration<{ id: any }> & WidgetDecorationSpec)[];
  return found.length ? found[0].from : null;
}
