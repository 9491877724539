<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import { history } from "prosemirror-history";
  import { keymap } from "prosemirror-keymap";
  import { EditorState, Plugin } from "prosemirror-state";
  import { EditorView } from "prosemirror-view";
  import { dropCursor } from "prosemirror-dropcursor";
  import { gapCursor } from "prosemirror-gapcursor";
  import { DOMParser, DOMSerializer } from "prosemirror-model";

  import "./editor.css";

  import { schema } from "./schema";
  import BubbleMenu from "./BubbleMenu.svelte";
  import MenuBar from "./MenuBar.svelte";
  import { placeholderPlugin } from "./upload";
  import { baseKeymap } from "./keymap";

  const domParser = DOMParser.fromSchema(schema);
  const domSerializer = DOMSerializer.fromSchema(schema);

  // De donde tomar el contenido y a donde sincronizarlo
  export let textareaEl: HTMLTextAreaElement;
  $: textareaEl.hidden = true;

  let wrapperEl: HTMLElement;

  let state: EditorState<any>;
  let view: EditorView<any>;
  onMount(() => {
    const firstStateEl = document.createElement("div");
    firstStateEl.innerHTML = textareaEl.value;
    const doc = domParser.parse(firstStateEl);

    state = EditorState.create({
      schema,
      doc,
      plugins: [
        new Plugin({
          view: (editorView) => {
            editorView.dom.parentElement.replaceWith(editorView.dom);
            return {
              update(view, lastState) {
                if (
                  lastState &&
                  lastState.doc.eq(view.state.doc) &&
                  lastState.selection.eq(view.state.selection)
                ) {
                  return;
                }
                state = view.state;
              },
            };
          },
        }),
        dropCursor(),
        gapCursor(),
        //menubar(schema),
        history(),
        keymap(baseKeymap),
        placeholderPlugin,
      ],
    });

    view = new EditorView(wrapperEl, { state });
  });
  onDestroy(() => {
    view.destroy();
    textareaEl.hidden = false;
  });

  $: {
    if (state) {
      const frag = domSerializer.serializeFragment(state.doc.content);
      const el = document.createElement("div");
      el.appendChild(frag);
      textareaEl.innerHTML = el.innerHTML;
    }
  }
</script>

<div class="editor">
  {#if view}
    <BubbleMenu {view} {state} />
    <MenuBar {view} {state} />
  {/if}
  <!-- this element gets replaced with the editor itself when mounted -->
  <div bind:this={wrapperEl} />
</div>
